import React, { Component } from "react"
import Layout from "../components/layout"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"
import mail from "../images/mail.svg"
import padlockClosed from "../images/padlock-closed.svg"
import axios from "axios"
import { AUTH_TOKEN_KEY, PARAM_CARD_CORECT_NOT_FOUND, PARAM_CARD_NMBRE, translate, URL_LOGIN } from "../utils"

class Oups extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    cardNmbre: ''
  }

  componentDidMount() {
    if (this.props.location) {
      const query = new URLSearchParams(this.props.location.search);
      if (query.get(PARAM_CARD_NMBRE) !== null && (query.get(PARAM_CARD_NMBRE) !== 'false')) {
        this.setState({cardNmbre: query.get(PARAM_CARD_NMBRE)})
      }else {
        console.log(`${PARAM_CARD_NMBRE} not found from query`)
      }
    }
  }

  render() {
    return (
      <Layout withBg>
        <div className="formWrapper">
          <Container>
            <Row className="justify-content-center">
              <Col md="12" lg="6" xl="4">
                <div className="text-center">
                  <h1 className="mb-3 h1-signupin-yellow">{translate('oepsss')+"!"}</h1>
                  <p>{translate('we konden geen bedrijf vinden dat aan dit nummer is gekoppeld of het ingevoerde nummer is onjuist')}: <b>{`#${this.state.cardNmbre}`}</b></p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg="3">
                <a href={'/'}>
                <div className="row justify-content-center m-3"> 
                  <Button variant="success" className="button-yellow">
                    {translate('herintroduceren')}
                  </Button>
                  </div>
                </a>
              </Col>
              <Col lg="3">
                <a href={`/sign-up-three?${PARAM_CARD_NMBRE}=${this.state.cardNmbre}&${PARAM_CARD_CORECT_NOT_FOUND}=${true}`}>
                <div className="row justify-content-center m-3"> 
                  <Button variant="primary" className="button-yellow">
                    {translate('het nummer is correct')}
                  </Button>
                  </div>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default Oups
